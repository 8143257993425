<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">EVIRONMENT DAMAGE IDENTIFICATION</p>
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            确定污染物性质；确定生态环境遭受损害的性质、范围和程度、评定因果关系；评定污染治理与运行成本已经防止损害扩大、修复生态环境的措施或方案。
        </div>
        <img class="img" style="width:100%" v-lazy="require('../../assets/range/1.jpg')">
        <div class="pathology_title">
            污染物性质环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            主要从事固体废物鉴定、危险废物鉴定、有毒物质（不包括危险废物）鉴定、放射性废物鉴定、污染物筛查及理化性质鉴定
        </div>
        <div class="pathology_title">
            地表水和沉积物环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            主要确定水功能，识别特征污染物，确定地表水和沉积物环境基线，确认地表水和沉积物环境质量是否收到损害，确定地表水和沉积物环境损害的时空范围和程度，判定污染环境行为与地表水和沉积物环境损害之间的因果关系，制定修复方案建议，评估损害数额，评估修复效果等。
        </div>
        <div class="pathology_title">
            空气污染环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            主要识别特征污染物，确定环境空气基线，确认环境空气质量与基线相比是否收到损害，确定环境空气损害的时空范围和程度，判定污染环境行为与环境空气损害之间的因果关系，制定废气治理方案建议，评估环境空气损害数额，评估治理效果等。
        </div>
        <div class="pathology_title">
            土壤与地下水环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            主要确定土地利用类型、地下水功能区人、土壤生态系统功能，识别特征污染物，确定环境基线，确认土壤环境质量、地下水环境质量、土壤生态系统功能是否受到损害，确认损害的时空范围和程度，判定因果惯性，制定修复方案，评估损害数额、评估修复效果等。        
        </div>
        <div class="pathology_title">
            近岸海洋与海岸带环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            主要确定近岸海洋、海岸带和海岛功能及生态系统功能，识别特征污染物、物种等，确定环境基线水平，确定环境损害的时空范围和程度，判定因果关系，制定修复方案建议，评估损害数额，评估修复效果等。
        </div>
        <div class="pathology_title">
            生态系统环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            主要针对生态破坏行为致植物损害、动物损害、微生物、森林系统、草原生态系统、湿地生态系统、荒漠生态系统、海洋生态系统、河流、湖泊生态系统、冻原生态系统、农田生态系统、城市生态系统损害鉴定，矿产资源开采行为致矿山地质环境破坏、土地损毁及生态功能损害鉴定，确定基线水平，确定损害时间、类型、范围和程度，判定因果关系，制定修复方案建议，评估损害数额，评估修复效果等。
        </div>
        <div class="pathology_title">
            其他类环境损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            包括噪声、振动、光、热、电磁辐射、电离辐射损害鉴定，识别污染源，评估影响范围确定损害数量和程度，判定因果关系，制定修复方案建议，评估损害数额，评估修复效果等包括噪声、振动、光、热、电磁辐射、电离辐射损害鉴定，识别污染源，评估影响范围确定损害数量和程度，判定因果关系，制定修复方案建议，评估损害数额，评估修复效果等。
        </div>
        
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
}
</script>
